<template>
  <div>
    <h5
      class="
        is-uppercase
        has-text-weight-medium has-text-centered has-text-primary
        p-2
        mb-2
        has-background-light
      "
    >
      Cadastrar Clínica
    </h5>

    <div class="columns">
      <div class="column is-2">
        <Upload :id="clinic._id" :prefix="'clinic'" />
      </div>
      <div class="column">
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                :message="errors[0]"
                :type="errors[0] ? 'is-danger' : ''"
                class="mb-2"
              >
                <b-input v-model="clinic.name" placeholder="Nome"></b-input>
              </b-field>
            </ValidationProvider>

            <div class="columns">
              <div class="column">
                <b-field>
                  <b-input
                    :disabled="!clinic.subscriber"
                    v-model="clinic.maxagenda"
                    placeholder="Max Agenda Key"
                  ></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input
                    :disabled="!clinic.subscriber"
                    v-model="clinic.whatsapp"
                    v-mask="'(##) # #### ####'"
                    placeholder="Whatsapp"
                  ></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-checkbox v-model="clinic.subscriber">Assinante</b-checkbox>
                </b-field>
              </div>
            </div>

            <Address
              :address="clinic.address"
              @setAddress="(v) => (clinic.address = v)"
            />

            <b-button native-type="submit" type="is-primary">Enviar</b-button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Upload from "@/components/Upload.vue";
import Address from "../../components/Address.vue";
extend("required", {
  ...required,
  message: "Esse campo é requerido",
});
const _clinic = {
  name: "",
  address: {},
  subscriber: false,
  active: true,
};
export default {
  name: "Clinic",
  directives: { mask },
  components: {
    ValidationProvider,
    ValidationObserver,
    Upload,
    Address,
  },
  data() {
    return {
      clinic: _clinic,
    };
  },
  computed: {
    clinicId() {
      return this.$route.params.clinicId || this.clinic._id;
    },
  },
  created() {
    if (this.clinicId)
      this.$http
        .get(`${process.env.VUE_APP_URI}clinic/${this.clinicId}`)
        .then((response) => {
          this.clinic = JSON.parse(JSON.stringify(response.data));
        });
  },
  methods: {
    onSubmit() {
      const self = this;

      self.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        if (self.clinic._id)
          self.$http
            .put(
              `${process.env.VUE_APP_URI}clinic/${self.clinic._id}`,
              self.clinic
            )
            .then(() => {
              self.$buefy.toast.open({
                message: "Dados Enviados",
                type: "is-info",
              });
            });

        if (!self.clinic._id)
          self.$http
            .post(`${process.env.VUE_APP_URI}clinic`, self.clinic)
            .then((response) => {
              self.$set(self.clinic, "_id", response.data._id);
              self.$buefy.toast.open({
                message: "Dados Enviados",
                type: "is-success",
              });
            });
      });
    },
  },
};
</script>