<template>
  <div>
    <!-- address -->
    <div class="columns mb-0">
      <div class="column is-4">
        <ValidationProvider name="zip" rules="required" v-slot="{ errors }">
          <b-field :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
            <b-input
              v-on:keyup.native="getAddress"
              v-model="address.zip"
              v-mask="'##.###-###'"
              placeholder="Cep"
            ></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column is-8">
        <ValidationProvider name="street" rules="required" v-slot="{ errors }">
          <b-field :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
            <b-input v-model="address.street" placeholder="Rua"></b-input>
          </b-field>
        </ValidationProvider>
      </div>
    </div>

    <div class="columns mb-0">
      <div class="column is-4">
        <ValidationProvider name="number" rules="required" v-slot="{ errors }">
          <b-field :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
            <b-input v-model="address.number" placeholder="Número"></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column is-3">
        <ValidationProvider name="complement">
          <b-field>
            <b-input v-model="address.complement" placeholder="Comp."></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column is-5">
        <ValidationProvider
          name="neighborhood"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
            <b-input
              v-model="address.neighborhood"
              placeholder="Bairro"
            ></b-input>
          </b-field>
        </ValidationProvider>
      </div>
    </div>

    <div class="columns mb-0">
      <div class="column is-8">
        <ValidationProvider name="city" rules="required" v-slot="{ errors }">
          <b-field :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
            <b-input v-model="address.city" placeholder="Cidade"></b-input>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column is-4">
        <ValidationProvider name="state" rules="required" v-slot="{ errors }">
          <b-field :message="errors[0]" :type="errors[0] ? 'is-danger' : ''">
            <b-input v-model="address.state" placeholder="Uf"></b-input>
          </b-field>
        </ValidationProvider>
      </div>
    </div>
    <!-- end address -->
  </div>
</template>

<script>
// Local Directive
import { mask } from "vue-the-mask";
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Esse campo é requerido",
});
export default {
  name: "Address",
  props: ["address"],
  directives: { mask },
  components: {
    ValidationProvider,
  },
  data() {
    return {
      //address: {},
      foundAddress: false,
    };
  },
  methods: {
    getAddress: function () {
      this.foundAddress = false;
      if (/^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/.test(this.address.zip)) {
        let zip = this.address.zip.replace(/\D/g, "");
        this.$http
          .get(`https://viacep.com.br/ws/${zip}/json`)
          .then((response) => {
            if (response.erro) {
              this.address = {};
              this.foundAddress = false;
              return;
            }
            const address = {
              zip: response.data.cep,
              street: response.data.logradouro,
              neighborhood: response.data.bairro,
              city: response.data.localidade,
              state: response.data.uf,
            };
            this.$emit("setAddress", address);
            this.foundAddress = true;
          });
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>